import React from 'react'
import styled from 'styled-components'
import { FONT, COLOR, SPACING } from '../constants'

const ContributeLink = styled.a`
  font-family: ${FONT.FAMILY.BODY};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  line-height: 1.5;
  color: ${COLOR.GRAY.MEDIUM};
  border-bottom: ${COLOR.WHITE} 2px solid;
  padding-bottom: ${SPACING.XXS};
  text-decoration: none;
  font-size: ${FONT.SIZE.S};

  @media (min-width: 600px) {
    font-size: ${FONT.SIZE.L};
  }

  &:hover {
    border-bottom: ${COLOR.TEAL.MEDIUM} 2px solid;
    color: ${COLOR.TEAL.MEDIUM};
  }

  @media (min-width: 950px) {
    display: inline-block;
    font-family: ${FONT.FAMILY.HEADING};
    font-weight: ${FONT.WEIGHT.BOLD};
    font-size: ${FONT.SIZE.L};
    line-height: 25px;
    color: ${COLOR.GRAY.DARK};
    background-color: ${COLOR.WHITE};
    border-radius: 200px;
    border: 1px solid ${COLOR.GRAY.DARK};
    padding: 8px ${SPACING.S};
    margin-right: 0;
    transition: 0.2s ease-in background-color, 0.2s ease-in color,
      0.2s ease-in border;
    height: 42px;

    &:visited {
      color: ${COLOR.TEAL.MEDIUM};
    }
    &:hover {
      color: ${COLOR.WHITE};
      background-color: ${COLOR.TEAL.MEDIUM};
      border: 1px solid ${COLOR.TEAL.MEDIUM};
    }
  }
`

const ContributeButton = () => (
  <ContributeLink href="https://docs.google.com/forms/d/e/1FAIpQLSdqhILqoAWF9wPgXCLLO53X8Ca987ngCz8aT2PmHneOqWY8RA/viewform?usp=sf_link">
    Contribute
  </ContributeLink>
)

export default ContributeButton
