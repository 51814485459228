/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import styled from 'styled-components'
import { COLOR, FONT, SPACING } from '../constants'
import { P, A } from './Typography'
import ContentWrapper from './ContentWrapper'

const FooterContainer = styled.footer`
  margin-top: 120px;
  border-top: 4px solid ${COLOR.GRAY.PALE};
  margin-bottom: ${SPACING.XXL};
  flex-shrink: 0;
`

const ByContainer = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    width: 50%;
  }
`

const FooterHeading = styled.p`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.L};
  line-height: 26px;
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.GRAY.MEDIUM};
  margin-bottom: ${SPACING.XXXS};
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${SPACING.L};

  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const Contribution = styled.div`
  width: 100%;
  margin-top: ${SPACING.L};

  @media (min-width: 800px) {
    flex-direction: row;
    margin-top: 0px;
    width: 50%;
  }
`

const Footer = () => (
  <FooterContainer>
    <ContentWrapper>
      <ContentContainer>
        <ByContainer>
          <FooterHeading>
            Design Patterns for Mental Health
            {` ${new Date().getFullYear()}`}
          </FooterHeading>
          <P>
            by <A href="https://wearesnook.com/">Snook</A>,{' '}
            <A href="http://publicpolicylab.org/">PublicPolicy Lab</A> &{' '}
            <A href="https://www.barnardos.org.uk/">Barnardo&rsquo;s</A>
            <br />
            Funded by <A href="https://www.nominet.uk/">Nominet</A>
          </P>
        </ByContainer>

        <Contribution>
          <P>
            Interested in contributing? <br />{' '}
            <A href="https://docs.google.com/forms/d/e/1FAIpQLSdqhILqoAWF9wPgXCLLO53X8Ca987ngCz8aT2PmHneOqWY8RA/viewform?usp=sf_link">
              Get in touch
            </A>
          </P>
        </Contribution>
      </ContentContainer>
    </ContentWrapper>
  </FooterContainer>
)

export default Footer
