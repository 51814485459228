export const BREAKPOINT = {
  S: '37rem', // 592px
  M: '58rem', // 928px
  L: '65rem', // 1024px
}

export const COLOR = {
  GRAY: {
    DARK: '#2D3748',
    MEDIUM: '#4A5568',
    LIGHT: '#CBD5E0',
    PALE: '#EDF2F7',
  },
  TEAL: {
    MEDIUM: '#217388',
    LIGHT: '#C8EDED',
  },
  BLUE: {
    MEDIUM: '#067699',
    LIGHT: '#D9F2FA',
  },
  GREEN: {
    MEDIUM: '#0B505F',
    LIGHT: '#E5FFF6',
  },
  WHITE: '#FFFFFF',
}

export const FONT = {
  FAMILY: {
    HEADING: 'Open sans, Helvetica, sans-serif',
    BODY: 'Roboto, Helvetica, sans-serif',
  },
  SIZE: {
    XS: '12px',
    S: '14px',
    M: '16px',
    L: '18px',
    XL: '21px',
    XXL: '28px',
    XXXL: '48px',
    XXXXL: '64px',
  },
  WEIGHT: {
    REGULAR: 400,
    MEDIUM: 500,
    BOLD: 700,
  },
}

export const SPACING = {
  XXXS: '.125rem', // 2px
  XXS: '.25rem', // 4px
  XS: '.5rem', // 8px
  S: '.75rem', // 12px
  M: '1rem', // 16px
  L: '1.5rem', // 24px
  XL: '2rem', // 32px
  XXL: '3rem', // 48px
  XXXL: '4.5rem', // 72px
}

export const SHADOW = {
  TOPBAR: ' 0px 3px 3px rgba(0, 0, 0, 0.1)',
  CARD: '0px 4px 4px rgba(45, 55, 72, 0.12)',
}

export const WIDTH = {
  WRAPPER: '64rem', // 1024px
  LINE_LIMIT: '37rem', // 592px
  HOME_HERO_LIMIT: '30rem', // 480px
  ABOUT_HERO_LINE_LIMIT: '41rem', // 672px
}
