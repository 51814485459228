import styled from 'styled-components'
import { SPACING, WIDTH } from '../constants'

const ContentWrapper = styled.div`
  max-width: ${WIDTH.WRAPPER};
  margin: 0 auto;
  padding-left: ${SPACING.M};
  padding-right: ${SPACING.M};
`

export default ContentWrapper
