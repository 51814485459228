import styled from 'styled-components'
import { COLOR, FONT, SPACING } from '../constants'

export const HeroHeading = styled.h1`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: -1.5%;
  line-height: 56px;
  color: ${COLOR.GR};
  margin-bottom: ${SPACING.S};
`

export const H1 = styled.h1`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: -1.5%;
  line-height: 56px;
  color: ${COLOR.GR};
`

export const H2 = styled.h2`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: ${FONT.SIZE.XXL};
  color: ${COLOR.GR};
  margin-top: ${SPACING.XXXL};
  position: relative;
`
export const H3 = styled.h3`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: 26px;
  color: ${COLOR.GR};
  margin-bottom: ${SPACING.XS};
  margin-top: ${SPACING.L};
`
export const H4 = styled.h4`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: ${SPACING.L};
  color: ${COLOR.GR};
`

export const P = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.REGULAR};
  letter-spacing: 0%;
  line-height: ${SPACING.L};
  color: ${COLOR.GRAY.DARK};
  margin-bottom: ${SPACING.XS};

  & + p {
    margin-top: ${SPACING.XL};
  }
`

export const B = styled.b`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: ${SPACING.L};
  color: ${COLOR.GRAY.DARK};
  margin-bottom: ${SPACING.XS};
  margin-top: ${SPACING.XS};

  & + p {
    margin-top: ${SPACING.L};
  }
`

export const A = styled.a`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.M};
  line-height: 1.5;
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.TEAL.MEDIUM};
  &:visited {
    color: ${COLOR.TEAL.MEDIUM};
  }
  &:hover {
    color: ${COLOR.GRAY.DARK};
  }
`

export const UL = styled.ul`
  margin-bottom: ${SPACING.XXL};
`

export const SideNavHeading = styled.h3`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.L};
  line-height: 26px;
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.GRAY.MEDIUM};
  margin-bottom: ${SPACING.S};
`
export const Quote = styled.blockquote`
  font-family: ${FONT.FAMILY.BODY};
  font-size: 24px;
  line-height: 1.5;
  font-weight: ${FONT.WEIGHT.BOLD};
  font-style: italic;
  color: ${COLOR.GREEN.MEDIUM};
  margin-bottom: ${SPACING.M};
  margin-top: ${SPACING.L};
  margin-left: 0;

  p:last-of-type {
    font-style: normal;
    color: ${COLOR.GRAY.MEDIUM};
    font-weight: ${FONT.WEIGHT.REGULAR};
  }

  p:first-of-type {
    margin-bottom: 0;
    color: ${COLOR.GREEN.MEDIUM};
    font-style: italic;
    font-weight: ${FONT.WEIGHT.BOLD};
  }
`
export const QuoteAuthor = styled.blockquote`
  font-family: ${FONT.FAMILY.BODY};
  font-size: 24px;
  line-height: 1.5;
  font-weight: ${FONT.WEIGHT.BOLD};
  font-style: italic;
  color: ${COLOR.GREEN.MEDIUM};
  margin-bottom: ${SPACING.M};
  margin-top: ${SPACING.L};
  margin-left: 0;
`

export const AboutHeroHeading = styled.h1`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: -1.5%;
  line-height: 56px;
  color: ${COLOR.WHITE};
`

export const BlogHeading = styled.h1`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: -1.5%;
  line-height: 56px;
  color: ${COLOR.GR};
  margin-bottom: ${SPACING.S};
`

export const BlogHeadingLink = styled.a`
  display: block;
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: ${FONT.SIZE.XXL};
  margin-top: ${SPACING.XL};
  margin-bottom: ${SPACING.S};
  position: relative;
  text-decoration: underline;
  color: ${COLOR.TEAL.MEDIUM};
  &:visited {
    color: ${COLOR.TEAL.MEDIUM};
  }
  &:hover {
    color: ${COLOR.GRAY.DARK};
    cursor: pointer;
  }
`

export const AboutHeroLink = styled.a`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: -1.5%;
  line-height: 56px;
  color: ${COLOR.WHITE};

  &:visited {
    color: ${COLOR.WHITE};
  }
  &:hover {
    color: ${COLOR.GRAY.DARK};
  }
`
