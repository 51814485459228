import React from 'react'
import styled from 'styled-components'
import { FONT, COLOR, SPACING } from '../constants'

const StyledSlackButton = styled.a`
  font-family: ${FONT.FAMILY.BODY};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  line-height: normal;
  color: ${COLOR.GRAY.MEDIUM};
  border-bottom: ${COLOR.WHITE} 2px solid;
  padding-bottom: ${SPACING.XXS};
  text-decoration: none;
  font-size: ${FONT.SIZE.S};

  svg {
    display: none;
  }

  @media (min-width: 600px) {
    font-size: ${FONT.SIZE.L};
  }

  &:hover {
    border-bottom: ${COLOR.TEAL.MEDIUM} 2px solid;
    color: ${COLOR.TEAL.MEDIUM};
  }

  @media (min-width: 950px) {
    display: flex;
    font-family: ${FONT.FAMILY.HEADING};
    font-weight: ${FONT.WEIGHT.BOLD};
    font-size: ${FONT.SIZE.L};
    line-height: 25px;
    text-decoration: none;
    color: ${COLOR.WHITE};
    background-color: ${COLOR.TEAL.MEDIUM};
    border-radius: 200px;
    border: 1px solid ${COLOR.TEAL.MEDIUM};
    padding: 8px ${SPACING.S};
    transition: 0.2s ease-in background-color, 0.2s ease-in color;
    filter: drop-shadow(0px 4px 4px rgba(45, 55, 72, 0.12));

    svg {
      display: block;
      margin-bottom: 0px;
      margin-right: ${SPACING.XXS};
      transform: translateY(1px);
      fill: white;
      transition: 0.2s ease-in fill;
    }

    &:visited {
      color: ${COLOR.WHITE};
    }

    &:hover {
      color: ${COLOR.TEAL.MEDIUM};
      background-color: ${COLOR.WHITE};
      border: 1px solid ${COLOR.TEAL.MEDIUM};
      svg {
        fill: ${COLOR.TEAL.MEDIUM};
      }

      @media (max-width: 420px) {
        svg {
          margin-right: 0px;
        }
        span {
          display: none;
        }
      }
    }
  }
`
const SlackButton = () => (
  <StyledSlackButton href="https://mhdp-slack.herokuapp.com/">
    {/* prettier-ignore */}
    <svg xmlns="http://www.w3.org/2000/svg" height="26" width="26" viewBox="-18.4 -30.7 159.6 184.2"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9C5.8 90.5 0 84.7 0 77.6c0-7.1 5.8-12.9 12.9-12.9h12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9 7.1 0 12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9-7.1 0-12.9-5.8-12.9-12.9V77.6zm12.9-51.8c-7.1 0-12.9-5.8-12.9-12.9C32.3 5.8 38.1 0 45.2 0c7.1 0 12.9 5.8 12.9 12.9v12.9zm0 6.5c7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2c0-7.1 5.8-12.9 12.9-12.9h32.3zM97 45.2c0-7.1 5.8-12.9 12.9-12.9 7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9H97zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9-7.1 0-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0c7.1 0 12.9 5.8 12.9 12.9zM77.6 97c7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9-7.1 0-12.9-5.8-12.9-12.9V97zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9 0-7.1 5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9z" /></svg>
    Join our Slack
  </StyledSlackButton>
)

export default SlackButton
