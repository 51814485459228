import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { BREAKPOINT, COLOR, FONT, SPACING, SHADOW, WIDTH } from '../constants'
import ContributeButton from './ContributeButton'
import SlackButton from './SlackButton'
import Logo from '../images/mh-logo.png'
import Logo2x from '../images/mh-logo-2x.png'

const StyledHeader = styled.header`
  position: relative;
  box-shadow: ${SHADOW.TOPBAR};
  z-index: 999;
  background-color: ${COLOR.WHITE};
  width: 100%;
  @media (min-width: ${BREAKPOINT.S}) {
    position: fixed;
  }
`

const Wrapper = styled.div`
  max-width: ${WIDTH.WRAPPER};
  margin: 0 auto;
  padding: ${SPACING.S} 0px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 10px;
  margin-left: ${SPACING.M};

  @media (min-width: 500px) and (max-width: 949px) {
    flex-basis: 50%;
  }
  a {
    margin: 0 auto;
    @media (min-width: 600px) {
      margin: 0px 0px;
    }
  }
`

const LogoImg = styled.img`
  margin-bottom: 0px;
`

const TopNav = styled.nav`
  flex-grow: 1;
  @media (min-width: 950px) {
    flex-grow: 0;
  }
  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: ${SPACING.S};
    padding-left: ${SPACING.M};
    padding-right: ${SPACING.M};
    padding-top: 10px;
    border-top: 1px solid ${COLOR.GRAY.PALE};
    @media (min-width: 649px) {
      justify-content: space-between;
    }

    @media (min-width: 950px) {
      padding-top: 0px;
      margin-top: 0px;
      margin-right: ${SPACING.M};
      border-top: 1px solid ${COLOR.WHITE};
    }
  }

  li:first-of-type {
    margin-left: 0;
  }

  li:last-of-type {
    @media (max-width: 361px) {
      margin-left: 0px;
    }
    @media (min-width: 950px) {
      margin-left: ${SPACING.M};
    }
  }
  li {
    list-style: none;
    margin-left: ${SPACING.M};
    margin-bottom: ${SPACING.XXS};

    @media (min-width: 650px) {
      margin-left: ${SPACING.L};
      margin-bottom: 0px;
    }
  }
`

const StyledInternalLink = styled(Link)`
  font-family: ${FONT.FAMILY.BODY};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  line-height: 1.5;
  color: ${COLOR.GRAY.MEDIUM};
  border-bottom: ${COLOR.WHITE} 2px solid;
  padding-bottom: ${SPACING.XXS};
  text-decoration: none;
  font-size: ${FONT.SIZE.S};

  @media (min-width: 600px) {
    font-size: ${FONT.SIZE.L};
  }

  &:hover {
    border-bottom: ${COLOR.TEAL.MEDIUM} 2px solid;
    color: ${COLOR.TEAL.MEDIUM};
  }
`

const activeLinkStyle = {
  borderBottom: `${COLOR.TEAL.MEDIUM} 2px solid`,
  color: COLOR.TEAL.MEDIUM,
}

const Header = () => (
  <StyledHeader>
    <Wrapper>
      <LogoWrapper>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <LogoImg
            src={Logo}
            srcSet={`${Logo} 1x, ${Logo2x} 2x`}
            alt="Design patterns for Mental Health"
          />
        </Link>
      </LogoWrapper>
      <TopNav>
        <ul>
          <li>
            <StyledInternalLink to="/principles" activeStyle={activeLinkStyle}>
              Principles
            </StyledInternalLink>
          </li>
          <li>
            <StyledInternalLink to="/patterns" activeStyle={activeLinkStyle}>
              Patterns
            </StyledInternalLink>
          </li>
          <li>
            <StyledInternalLink to="/examples" activeStyle={activeLinkStyle}>
              Examples
            </StyledInternalLink>
          </li>
          <li>
            <StyledInternalLink to="/about" activeStyle={activeLinkStyle}>
              About
            </StyledInternalLink>
          </li>
          <li>
            <ContributeButton />
          </li>
          <li>
            <SlackButton />
          </li>
        </ul>
      </TopNav>
    </Wrapper>
  </StyledHeader>
)

export default Header
