import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { BREAKPOINT } from '../constants'

import Header from './header'
import './layout.css'
import Footer from './Footer'

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
const Main = styled.main`
  padding-top: 0px;
  flex-grow: 1;

  @media (min-width: ${BREAKPOINT.S}) {
    padding-top: 79px;
  }
`

const Layout = ({ title, seoDescription, children }) => {
  const data = useStaticQuery(graphql`
    query MyQuery($filter: ContentfulSeoMetadataFilterInput = {}) {
      seoData: allContentfulSeoMetadata(
        filter: $filter
        limit: 1
        sort: { fields: updatedAt, order: DESC }
      ) {
        edges {
          node {
            seoDescription
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta lang="en" />
        <title>{title}</title>
        <meta
          name="description"
          content={seoDescription || data.seoData.edges[0].node.seoDescription}
        />
      </Helmet>
      <Container>
        <Header />

        <Main>{children}</Main>
        <Footer />
      </Container>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  seoDescription: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  seoDescription: null,
}

export default Layout
